import * as React from "react";

import FeaturedPages from "../FeaturedPages";
import HomeBanner from "../HomeBanner";
import IFrameNationalTeam from "../IFrameNationalTeam";
import LatestNews from "../LatestNews";
import LeaderboardOrSquareAdvertisement from "../LeaderboardOrSquareAdvertisement";
import NewsCategories from "../NewsCategories";
import Page from "../Page";
import styles from "./style.scss";

const LABELS = {
  NATIONALTEAM_UPCOMING_TITLE: "National Team",
};

function Home({ home, onGetHomePage }) {
  const getPage = React.useCallback(onGetHomePage, [onGetHomePage]);

  React.useEffect(() => {
    const script = document.createElement("script");

    // eslint-disable-next-line func-names
    script.innerHTML = (function (d, s, id) {
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://embedsocial.com/cdn/ht.js";
      d.getElementsByTagName("head")[0].appendChild(js);
    })(document, "script", "EmbedSocialHashtagScript");
    script.async = true;
  }, []);

  return (
    <Page path="/" onGetPage={getPage} scrollToTop>
      {({ page, isReady: isPageReady }) => {
        if (!(isPageReady && page)) {
          return null;
        }

        return (
          <section>
            <HomeBanner isFeatured pageSize={5} />
            <div className={styles.widget}>
              <h2 className={styles.sectionTitle}>
                {LABELS.NATIONALTEAM_UPCOMING_TITLE}
              </h2>
              <IFrameNationalTeam type="upcoming" limit={2} />
            </div>
            <LatestNews pageSize={6} />
            <NewsCategories pageSize={6} />
            <LeaderboardOrSquareAdvertisement uid="corporate-home-page-1" />
            <FeaturedPages data={home.featuredPages} />
            <div className={styles.content}>
              <h1 className={styles.title}>{page.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: page.html }} />
            </div>
            <LeaderboardOrSquareAdvertisement uid="corporate-home-page-2" />
            <div className={styles.instagramFeed}>
              <div
                className="embedsocial-hashtag"
                data-ref="ef7e1dc6ec1c2df28b49e8748fbe7dabfe33577c"
              />
            </div>
          </section>
        );
      }}
    </Page>
  );
}

export default Home;
