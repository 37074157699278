import * as React from "react";
import Glider from "react-glider";

import FullWidthBanner from "../FullWidthBanner";
import styles from "./style.scss";

function HomeBanner({
  isError,
  isReady,
  news,
  onGetLatestNews,
  isFeatured,
  pageSize,
}) {
  const [isTrackLoaded, setIsTrackLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!isReady) {
      onGetLatestNews({ isFeatured, pageSize });
    }
  }, [isReady, onGetLatestNews, isFeatured, pageSize]);

  if (isError) {
    return null;
  }

  // show skeleton
  if (!isReady) {
    return <div className={`${styles.banner} ${styles.skeleton}`} />;
  }

  return (
    <div className={styles.banner}>
      <Glider
        hasDots={news.length > 1}
        dots={`.${styles.dots}`}
        hasArrows={false}
        slidesToShow={1}
        duration={0.5}
        skipTrack
        onLoad={() => setIsTrackLoaded(true)}
      >
        <div className={`${isTrackLoaded ? "" : styles.track} glider-track`}>
          {news.map(({ id, title, summary, bannerImage, url }, index) => (
            <FullWidthBanner
              key={id}
              lazyload={index > 0}
              title={title}
              media={bannerImage}
              summary={summary}
              fetchPriority={index === 0 ? "high" : "low"}
              url={url}
              withOverlay
            />
          ))}
        </div>
      </Glider>
      <div className={styles.dots} />
    </div>
  );
}

export default HomeBanner;
