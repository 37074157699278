/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";

import Advertisement from "../Advertisement";
import styles from "./style.scss";

const ADVERT_TYPE = "leaderboard";
const WIDTH = "728";
const HEIGHT = "90";

function LeaderboardAdvertisement({ uid }) {
  return (
    <Advertisement type={ADVERT_TYPE} uid={uid}>
      {(advertisement, handleClick) => (
        <div className={styles.advert}>
          <img
            src={advertisement.images.src}
            srcSet={advertisement.images.srcset}
            alt={advertisement.images.alt}
            title={advertisement.name}
            className={styles.advertImg}
            width={WIDTH}
            height={HEIGHT}
            loading="lazy"
            decoding="async"
            crossOrigin="anonymous"
            onClick={handleClick}
            data-advert={advertisement.key}
            data-uid={uid}
          />
        </div>
      )}
    </Advertisement>
  );
}

export default LeaderboardAdvertisement;
