import IframeResizer from "iframe-resizer-react";
import * as React from "react";

const LABELS = {
  TITLE: "National Team",
};

function IFrameNationalTeam({ type, limit }) {
  return (
    <IframeResizer
      title={LABELS.TITLE}
      src={`${MC_URL}/widgets/national-team/${type}?limit=${limit}`}
      width="100%"
      height="350px"
      frameBorder="0"
      seamless="seamless"
      bodyMargin="0 0 2em"
    />
  );
}

export default IFrameNationalTeam;
