import * as React from "react";

import LeaderboardAdvertisement from "../LeaderboardAdvertisement";
import SquareAdvertisement from "../SquareAdvertisement";

function LeaderboardOrSquareAdvertisement({ uid }) {
  const [mql, setMql] = React.useState(null);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 54em)");
    setMql(mediaQuery.matches);
  }, []);

  if (mql === null) {
    return null;
  }

  if (mql === true) {
    return <LeaderboardAdvertisement uid={uid} />;
  }

  return <SquareAdvertisement uid={uid} />;
}

export default LeaderboardOrSquareAdvertisement;
