import * as React from "react";
import { Link } from "react-router-dom";

import ResponsivePicture from "../ResponsivePicture";
import defaultImage from "./img/default-image.jpg";
import styles from "./style.scss";

const LABELS = {
  TITLE: "News Categories",
};

function NewsCategories({
  isError,
  isReady,
  newsCategories,
  onGetNewsCategories,
  pageSize,
}) {
  React.useEffect(() => {
    if (!isReady) {
      onGetNewsCategories(pageSize);
    }
  }, [isReady, onGetNewsCategories, pageSize]);

  if (isError) {
    return null;
  }

  // show skeletons
  if (!isReady) {
    return (
      <div className={styles.newsCategories}>
        <h2 className={styles.title}>{LABELS.TITLE}</h2>
        <div className={styles.skeletonGrid}>
          {[...Array(4)].map((n, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.skeletonItem}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.newsCategories}>
      <h2 className={styles.title}>{LABELS.TITLE}</h2>
      <ul className={styles.list}>
        {newsCategories
          .filter((n) => n.isFeatured)
          .map((n) => (
            <li key={n.id} className={styles.item}>
              <Link to={n.url}>
                <div className={styles.thumbnail}>
                  {n.thumbnail && (
                    <ResponsivePicture
                      src={n.thumbnail.src}
                      srcSet={n.thumbnail.srcset}
                      alt={n.thumbnail.alt}
                      lazyload
                      crossOrigin="anonymous"
                      className={styles.thumbnailImg}
                    />
                  )}
                  {!n.thumbnail && (
                    <img
                      src={defaultImage}
                      alt={process.env.DESCRIPTION}
                      title={process.env.DESCRIPTION}
                      width={384}
                      height={384}
                      className={`${styles.thumbnailImg} ${styles.defaultImg}`}
                    />
                  )}
                </div>
                <span className={styles.itemTitle}>{n.title}</span>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default NewsCategories;
