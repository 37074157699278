import * as React from "react";
import { Link } from "react-router-dom";

import ResponsivePicture from "../ResponsivePicture";
import defaultImage from "./img/default-image.jpg";
import styles from "./style.scss";

function FeaturedPages({ data }) {
  return (
    <div className={styles.featuredPages}>
      <ul className={styles.list}>
        {data.map((n) => (
          <li key={n.id} className={styles.item}>
            <Link to={n.url}>
              <div className={styles.thumbnail}>
                {n.thumbnail && (
                  <ResponsivePicture
                    src={n.thumbnail.src}
                    srcSet={n.thumbnail.srcset}
                    alt={n.thumbnail.alt}
                    lazyload
                    crossOrigin="anonymous"
                    className={styles.thumbnailImg}
                  />
                )}
                {!n.thumbnail && (
                  <img
                    src={defaultImage}
                    alt={process.env.DESCRIPTION}
                    title={process.env.DESCRIPTION}
                    width={384}
                    height={384}
                    className={`${styles.thumbnailImg} ${styles.defaultImg}`}
                  />
                )}
              </div>
              <span className={styles.itemTitle}>{n.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FeaturedPages;
