import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import reducerRegistry from "../../reducerRegistry";
import { getNewsCategories } from "./actions";
import { REDUCER_NAME } from "./constants";
import Component from "./newsCategories";
import reducer, {
  selectIsError,
  selectIsReady,
  selectNewsCategories,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  newsCategories: selectNewsCategories,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetNewsCategories: (data) => dispatch(getNewsCategories(data)),
});

const NewsCategories = connect(mapStateToProps, mapDispatchToProps)(Component);

export default NewsCategories;

reducerRegistry.register(REDUCER_NAME, reducer);
