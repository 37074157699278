import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import reducerRegistry from "../../reducerRegistry";
import { getLatestNews } from "./actions";
import { REDUCER_NAME } from "./constants";
import Component from "./latestNews";
import reducer, {
  selectIsError,
  selectIsReady,
  selectLatestNews,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  news: selectLatestNews,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetLatestNews: (data) => dispatch(getLatestNews(data)),
});

const LatestNews = connect(mapStateToProps, mapDispatchToProps)(Component);

export default LatestNews;

reducerRegistry.register(REDUCER_NAME, reducer);
