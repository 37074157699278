import * as React from "react";
import Glider from "react-glider";

import NewsArticle from "../NewsArticle";
import styles from "./style.scss";

const LABELS = {
  TITLE: "Latest News",
};

function ContainerElement({ children }) {
  return <div className={styles.glider}>{children}</div>;
}

function LatestNews({
  isError,
  isReady,
  news,
  onGetLatestNews,
  limit,
  pageSize,
  displayAsRow,
}) {
  const [isTrackLoaded, setIsTrackLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!isReady) {
      onGetLatestNews({ pageSize });
    }
  }, [isReady, onGetLatestNews, pageSize]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return (
      <div className={styles.latestNews}>
        <h2 className={styles.title}>{LABELS.TITLE}</h2>
        <div className={styles.skeletonGrid}>
          {[...Array(3)].map((n, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={
                displayAsRow ? styles.skeletonItemRow : styles.skeletonItem
              }
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.latestNews}>
      <h2 className={styles.title}>{LABELS.TITLE}</h2>
      {displayAsRow ? (
        <div className={styles.grid}>
          {news.slice(0, limit || Infinity).map((n) => (
            <div
              key={n.id}
              className={`${styles.item} ${displayAsRow ? styles.itemRow : ""}`}
            >
              <NewsArticle data={n} displayAsRow={displayAsRow} />
            </div>
          ))}
        </div>
      ) : (
        <Glider
          hasDots={news.length > 3}
          slidesToShow={1}
          containerElement={ContainerElement}
          skipTrack
          onLoad={() => setIsTrackLoaded(true)}
          responsive={[
            {
              breakpoint: 864,
              settings: {
                slidesToShow: 3,
              },
            },
          ]}
        >
          <div className={`${isTrackLoaded ? "" : styles.track} glider-track`}>
            {news.map((n) => (
              <div key={n.id} className={styles.item}>
                <NewsArticle data={n} />
              </div>
            ))}
          </div>
        </Glider>
      )}
    </div>
  );
}

export default LatestNews;
